<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset"></search>
        <!-- <Button type="primary" size="small" ghost @click="onbinding" style="position: absolute;right: 100px;top: 41px;">绑定公众号用户</Button> -->
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ statusArr.find(info => info.id == row.status).title }}</span>
                </template>
                <template slot-scope="{ row }" slot="room_type">
                    <span v-if="row.room_type == '1'">儿童房</span>
                    <span v-if="row.room_type == '2'">主卧</span>
                    <span v-if="row.room_type == '3'">次卧</span>
                    <span v-if="row.room_type == '4'">客厅</span>
                    <span v-if="row.room_type == '5'">书房</span>
                    <span v-if="row.room_type == '6'">其他</span>
                </template>
                <template slot-scope="{ row }" slot="is_agree">
                    <span v-if="row.is_agree == 0">--</span>
                    <span v-if="row.is_agree == 1">自愿加入</span>
                </template>
                <template slot-scope="{ row }" slot="see">
                    <span :class="'now_state_' + row.see">{{ { 0: '待查看', 1: '查看待确认', 2: '已确认' }[row.see] || row.see }}</span>
                </template>
                <template slot-scope="{ row }" slot="sid">
                    {{ (serviceList.find(info => info.id == row.sid) || {}).title || row.sid }}
                </template>
                <template slot-scope="{ row }" slot="accout_id">
                    {{ (employeeList.find(info => info.id == row.accout_id) || {}).nickname || row.accout_id }}
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <!-- <Button type="error" size="small" ghost @click="onDelete(row.id)">删除</Button> -->
                        <Button type="primary" size="small" ghost @click="onEdit(row.re_id,row.id)">编辑</Button>
                        <!-- <Button type="primary" size="small" ghost @click="onDetail(row.re_id)">详情</Button> -->
                        <Button type="primary" size="small" ghost @click="onQuery(row.re_id,row.id)">查看</Button>
                        <Button type="success" size="small" ghost @click="onSend(row.re_order_no)">发送报告</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqCheckList, reqSendReport, reqServeList,wxAuth} from '@/lib/request/auth';
import { groupIdDef, reqAccountByGroupId } from '@/lib/request/auth2';
// import { reqCheckView, geteditForm, saveReservation } from '@/lib/request/auth';

export default {
    name: 'monitorManagerBillList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        let statusArr = [
            { id: '0', title: '禁用' },
            { id: '1', title: '启用' },
        ];
        this.statusArr = statusArr;
        return {
            searchs: [
                { id: hm.createId(), label: '编号', placeholder: '输入编号', type: 'input', bind: 'id' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: statusArr,
                },
            ],
            dataColumn: [
                { key: 'id', title: '编号', minWidth: 80 },
                { key: 're_id', title: '预约ID', minWidth: 100, tooltip: true },
                { key: 're_order_no', title: '预约单号', minWidth: 120, tooltip: true },
                { slot: 'sid', title: '服务ID', minWidth: 120 },
                { slot: 'room_type', title: '房间类型', minWidth: 120 },
                { slot: 'is_agree', title: '加入小太阳计划', minWidth: 120 },
                { slot: 'accout_id', title: '检测人员', minWidth: 120 },
                { slot: 'see', title: '报告状态', minWidth: 120 },
                {
                    key: 'ctime',
                    title: '检测时间',
                    minWidth: 120,
                    render(h, data) {
                        return h('div', new Date(data.row.ctime * 1000).Format('yyyy-MM-dd HH:mm:ss'));
                    },
                },
                { slot: 'status', title: '状态', minWidth: 100 },
                { slot: 'manager', title: '操作', minWidth: 200 },
            ],
            dataList: [],
            serviceList: [],
            employeeList: [],
            code:''
        };
    },
    mounted() {
        this.getServiceList();
        this.getEmployeeList();
        this.getList();
        // this.getwxAuth();
        // const params = new URLSearchParams(window.location.search);
        // const paramValue = params.get('code');
        // this.code =  paramValue;
        // if(this.code){
        //     this.getwxAuth();
        // }
       
    },
    methods: {
        getwxAuth(){
            wxAuth({
               code: this.code,
            }).then(res => {
                console.log('77')
               this.$Message('绑定成功')
            });
        },
        onbinding(){
            var redirect_uri = window.location.origin + '/app/index.html';
            console.log(redirect_uri,'redirect_uri')
            window.location.href =
                'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb993f857b2fbef0c&redirect_uri=' +redirect_uri + '&response_type=code&scope=snsapi_base#wechat_redirect';
        },
        onDetail(id){
            this.$router.push({path:'/monitorEdit',query:{
                id:id,
                isDetail:true
            }})
        },
        //获取服务列表
        getServiceList() {
            reqServeList({
                pageNums: 1,
                pageSize: 200,
            }).then(res => {
                this.serviceList = res?.data?.list || [];
            });
        },
        //获取检测人员列表
        getEmployeeList() {
            reqAccountByGroupId(groupIdDef.qa).then(res => {
                this.employeeList = res?.data?.list || [];
            });
        },
        //获取列表数据
        getList() {
            this.showAjaxLoading();
            reqCheckList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //编辑
        onEdit(id,zid) {
            console.log('onQuery', id);
            // this.goUrl('/monitorEdit', {
            //     id,
            // });
            this.$router.push({path:'/monitorEdit',query:{
                id:id,
                zid:zid,
                isDetail:false
            }})
        },
        //查看
        onQuery(id,zid) {
            console.log('onQuery', id);
            this.goUrl('/monitorBillDetail', {
                id,
                zid:zid

            });
        },
        //删除
        onDelete(id) {
            this.confirmDialog('确认删除吗').then(isOK => {
                if (isOK == 1) {
                    console.log('onDelete', id);
                }
            });
        },
        //发送报告
        onSend(re_order_no) {
            console.log('onSend', re_order_no);
            this.confirmDialog('确认发送报告吗').then(isOK => {
                if (isOK == 1) {
                    reqSendReport({
                        order_no: re_order_no,
                    })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .catch(res => {
                            this.fadeAlert(res.errmsg);
                        });
                }
            });
        },
    },
};
</script>
